import React, { useEffect, useState } from 'react'
import GetStartedForm from '../../../../Components/GetStartedForm/Index';


const PaidAdsPageHero = ({ isOpen, setIsOpen }) => {
    return (
        <section className="hero">
            <div className="hero-image">
                <div className="max-w-5xl mx-auto px-4">
                    <div className="block text-center pt-12">
                        <h1 className="text-5xl xl:text-6xl">We're an Affordable
                            <br />
                            <svg width="270" height="17" fill="none" className="absolute mt-12 ml-56 hidden md:block">
                                <path stroke="#48BB78" strokeWidth="2" d="M.5 3C183-3.5 145.5 10 123 14s118-4 146.5-7.5" />
                            </svg>
                            <span className="mr-2 text-green-500 font-semibold">
                                Pay Per Click Ads
                            </span>
                            <br />Management Agency
                        </h1>
                        <p className="py-5 text-md md:text-lg max-w-xl mx-auto">
                            Let us help you maximize your ROI by up to 500x with our expert PPC management services.
                        </p>
                    </div>
                    <div className="text-center">
                        <GetStartedForm isOpen={isOpen} setIsOpen={setIsOpen} />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default PaidAdsPageHero
