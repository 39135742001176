import React from 'react'
import toast from 'react-hot-toast'

const domainNameValidation = (URL) => {
    // Regular expression to match domain names
    const regex = new RegExp('^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,}$');
    return regex.test(URL);
};

const onSubmitHandler = (e) => {
    e.preventDefault();
    const inputUrl = e.target[0].value;
    domainNameValidation(inputUrl) ?
        window.location.replace(`/contact-us?website=${inputUrl}`) :
        toast.error('Please enter a valid domain name', {
            duration: 4000,
            position: 'top-center',
            style: {
                borderRadius: '10px',
                background: 'linear-gradient(94.33deg, #48BB78 5.89%, #94F3C3 111.03%)',
            },
        });
};

function ProposalForm({ placeholder }) {
    return (
        <>
            <div className=" py-8">
                <form className="flex flex-wrap relative" onSubmit={onSubmitHandler}>
                    <input type="text" name="website" required className="w-full py-4 outline-none rounded-xl border-none relative" placeholder="Please enter your domain" id="" />
                    <button className="absolute inset-0 my-2 text-xl mx-2 button w-1/3 left-auto rounded-lg text-gray-700">Get Started</button>
                </form>
            </div>
        </>
    )
}

export default ProposalForm
